<template>
  <section class="privacy-policy">
    <h2 class="privacy-policy__title text-title">Privacy Policy</h2>

    <p class="privacy-policy__sub-title privacy-policy__new-paragraph text-link">
      How We Collect and Use Information
    </p>
    <p class="privacy-policy__text text-base">
      We collect the following types of information about you:
    </p>

    <p class="privacy-policy__sub-title privacy-policy__new-paragraph text-link">
      Information you provide us directly:
    </p>
    <p class="privacy-policy__text text-base">
      We ask for certain information such as your username, real name, birthdate, address, phone
      number and e-mail address when you register for a browngrotta arts account, or if you
      correspond with us. We may also retain any messages you send through the Service and may
      collect information you provide in User Content you post to the Service. We use this
      information to operate, maintain, and provide to you the features and functionality of the
      Service. Your username and if you choose to disclose it, your real name, will be published
      publicly. Once published your username and / or real name may not be able to be removed.
    </p>

    <p class="privacy-policy__sub-title privacy-policy__new-paragraph text-link">
      Analytics information:
    </p>
    <p class="privacy-policy__text text-base">
      We may directly collect analytics data, or use third-party analytics tools and services, to
      help us measure traffic and usage trends for the Service. These tools collect information sent
      by your browser or mobile device, including the pages you visit and other information that
      assists us in improving the Service. We collect and use this analytics information in
      aggregate form such that it cannot reasonably be manipulated to identify any particular
      individual user.
    </p>

    <p class="privacy-policy__sub-title privacy-policy__new-paragraph text-link">
      Cookies information:
    </p>
    <p class="privacy-policy__text text-base">
      When you visit the Service, we may send one or more cookies — a small text file containing a
      string of alphanumeric characters — to your computer that uniquely identifies your browser and
      lets browngrotta arts help you log in faster and enhance your navigation through the site. A
      cookie may also convey information to us about how you use the Service (e.g., the pages you
      view, the links you click and other actions you take on the Service) and allow us or our
      business partners to track your usage of the Service over time. A persistent cookie remains on
      your hard drive after you close your browser. Persistent cookies may be used by your browser
      on subsequent visits to the site. Persistent cookies can be removed by following your web
      browser’s directions. A session cookie is temporary and disappears after you close your
      browser. You can reset your web browser to refuse all cookies or to indicate when a cookie is
      being sent. However, some features of the Service may not function properly if the ability to
      accept cookies is disabled.
    </p>

    <p class="privacy-policy__sub-title privacy-policy__new-paragraph text-link">
      Log file information:
    </p>
    <p class="privacy-policy__text text-base">
      Log file information is automatically reported by your browser or mobile device each time you
      access the Service. When you use our Service, our servers automatically record certain log
      file information. These server logs may include anonymous information such as your web
      request, Internet Protocol (“IP”) address, browser type, referring / exit pages and URLs,
      number of clicks and how you interact with links on the Service, domain names, landing pages,
      pages viewed, and other such information.
    </p>

    <p class="privacy-policy__sub-title privacy-policy__new-paragraph text-link">
      Clear gifs/web beacons information:
    </p>
    <p class="privacy-policy__text text-base">
      When you use the Service, we may employ clear gifs (also known as web beacons) which are used
      to anonymously track the online usage patterns of our Users. In addition, we may also use
      clear gifs in HTML-based emails sent to our users to track which emails are opened and which
      links are clicked by recipients. The information is allowed for more accurate reporting and
      improvement of the Service.
    </p>

    <p class="privacy-policy__sub-title privacy-policy__new-paragraph text-link">
      Device identifiers:
    </p>
    <p class="privacy-policy__text text-base">
      When you access the Service by or through a mobile device (including but not limited to
      smart-phones or tablets), we may access, collect, monitor and/or remotely store one or more
      “device identifiers,” such as a universally unique identifier (“UUID”). Device identifiers are
      small data files or similar data structures stored on or associated with your mobile device,
      which uniquely identify your mobile device. A device identifier may be data stored in
      connection with the device hardware, data stored in connection with the device’s operating
      system or other software, or data sent to the device by browngrotta arts. A device identifier
      may convey information to us about how you browse and use the Service. A device identifier may
      remain persistently on your device, to help you log in faster and enhance your navigation
      through the Service. You may be able to set your device to remove device identifiers. However,
      some features of the Service may not function properly if use or availability of device
      identifiers is impaired or disabled.
    </p>

    <p class="privacy-policy__sub-title privacy-policy__new-paragraph text-link">Location data:</p>
    <p class="privacy-policy__text text-base">
      When you access the Service by or through a mobile device, we may access, collect, monitor
      and/or remotely store “location data,” which may include GPS coordinates (e.g. latitude and/or
      longitude) or similar information regarding the location of your mobile device. Location data
      may convey to us information about how you browse and use the Service. You may be able to set
      your device to prevent collection of location data. However, some features of the Service,
      particularly location-based services, may not function properly if use or availability of
      location data is impaired or disabled.
    </p>

    <p class="privacy-policy__sub-title privacy-policy__new-paragraph text-link">
      Commercial and marketing communications:
    </p>
    <p class="privacy-policy__text text-base">
      We use the information we collect or receive, such as your email address, to communicate
      directly with you. We may send you emails containing newsletters, promotions, and special
      events. If you do not want to receive such email messages, you will be given the option to opt
      out or change your preferences. We also use your information to send you Service-related
      emails (e.g., account verification, purchase and billing confirmations and reminders,
      changes/updates to features of the Service, technical and security notices). You may not opt
      out of Service-related emails.
    </p>

    <p class="privacy-policy__sub-title privacy-policy__new-paragraph text-link">
      Use of certain service type information we collect about you:
    </p>
    <p class="privacy-policy__text text-base">
      We use or may use cookies, log file, device identifiers, location data and clear gifs
      information to: (a) remember information so that you will not have to re-enter it during your
      visit or the next time you visit the site; (b) provide custom, personalized content and
      information; (c) to provide and monitor the effectiveness of our Service; (d) monitor
      aggregate metrics such as total number of visitors, traffic, and demographic patterns; (e)
      diagnose or fix technology problems; (f) help you efficiently access your information after
      you sign in, and (g) automatically update the browngrotta arts application on your mobile
      devices.
    </p>

    <p class="privacy-policy__sub-title privacy-policy__new-paragraph text-link">
      Sharing of Your Information
    </p>
    <p class="privacy-policy__text text-base">
      We will not rent or sell your information into third parties outside browngrotta arts and its
      group companies (including any parent, subsidiaries and affiliates) without your consent,
      except we may share your information with third-party business partners and Trusted Users for
      the purpose of providing the Service to you. Third-party business partners will be given
      limited access to your information as is reasonably necessary to deliver the Service, and we
      will require that such third parties comply with this Privacy Policy, or operate under a
      similar privacy policy.<br /><br />
      <span class="privacy-policy__paragraph privacy-policy__new-paragraph"
        >What happens in the event of a change of control: </span
      >We may sell/divest/transfer browngrotta arts or a portion of its products, services, assets
      and/or businesses. If this occurs, your information such as customer names and email
      addresses, User Content and other user information related to the Service may be among the
      items sold or otherwise transferred in such a transaction. In the event of a change of
      control, we will ask the recipient of your personal information to notify you so that you may
      change your preferences at that time.<br /><br />
      <span class="privacy-policy__paragraph privacy-policy__new-paragraph"
        >Instances where we are required to share your information:
      </span>
      browngrotta arts cooperates with government and law enforcement officials to enforce and
      comply with the law. We may therefore disclose personal information, usage data, message data,
      and any other information about you, if we deem that it is reasonably necessary to: (a)
      satisfy any applicable law, regulation, legal process (such as a subpoena or court order), or
      enforceable governmental request; (b) enforce the Terms of Use, including investigation of
      potential violations thereof; (c) detect, prevent, or otherwise address fraud, security or
      technical issues; or (d) protect against harm to the rights, property or safety of browngrotta
      arts, its users or the public, as required or permitted by law.<br /><br />
      <span class="privacy-policy__paragraph privacy-policy__new-paragraph"
        >Sharing certain service type information we collect about you: </span
      >We may share certain service type information, including information obtained through tools
      such as cookies, log files, device identifiers, location data and clear gifs (such as
      anonymous usage data, referring/exit pages and URLs, platform types, number of clicks, etc.):
      (i) with our third-party business partners for the purposes described in the section above on
      “How We Collect and Use Information.” We may also aggregate or otherwise strip data of all
      personally identifying characteristics and may share that aggregated, anonymized data with
      third parties.
    </p>

    <p class="privacy-policy__sub-title privacy-policy__new-paragraph text-link">
      How We Store and Protect Your Information
    </p>
    <p class="privacy-policy__text text-base">
      <span class="privacy-policy__paragraph">Storage and Processing: </span>
      Your information collected through the Service will be stored and processed in the United
      States. Therefore, the information that browngrotta arts collects will be subject to the laws
      of the United States of America, regardless of the country from which your information
      originates.<br /><br />
      <span class="privacy-policy__paragraph privacy-policy__new-paragraph"
        >Keeping your information safe: </span
      >browngrotta arts cares about the security of your information and uses commercially
      reasonable safeguards to preserve the integrity and security of all information collected
      through the Service. To protect your privacy and security, we take reasonable steps (such as
      requesting a unique password) to verify your identity before granting you access to your
      account. You are responsible for maintaining the secrecy of your unique password and account
      information, and for controlling access to your email communications from browngrotta arts at
      all times. browngrotta arts cannot ensure or warrant the security of any information you
      transmit to browngrotta arts or guarantee that information on the Service may not be accessed,
      disclosed, altered, or destroyed so you do so at your own risk. Your privacy settings may also
      be affected by changes to the functionality of third-party sites and services that you add to
      the browngrotta arts Service, such as social networks. browngrotta arts is not responsible for
      the functionality or security measures of any third party.<br /><br />
      <span class="privacy-policy__paragraph privacy-policy__new-paragraph"
        >Compromise of information: </span
      >In the event that any information under our control is compromised as a result of a breach of
      security, browngrotta arts will take reasonable steps to investigate the situation and where
      appropriate, notify those individuals whose information may have been compromised and take
      other steps, in accordance with any applicable laws and regulations.
    </p>

    <p class="privacy-policy__sub-title privacy-policy__new-paragraph text-link">
      Your Choices about Your Information
    </p>
    <p class="privacy-policy__text text-base">
      <span class="privacy-policy__paragraph"
        >You control your account information and settings:
      </span>
      You may update your account information and email-communication preferences at any time by
      logging in to your account and changing your profile settings.
    </p>
    <p class="privacy-policy__text privacy-policy__new-paragraph text-base">
      We make every effort to promptly process all unsubscribe requests. As noted above, you may not
      opt out of Service-related communications (e.g., account verification, purchase and billing
      confirmations and reminders, changes/updates to features of the Service, technical and
      security notices).
    </p>
    <p class="privacy-policy__text privacy-policy__new-paragraph text-base">
      <span class="privacy-policy__paragraph">
        Opting out of collection of your information for Tracking / Advertising:
      </span>
      Please refer to your mobile device or browser’s technical information for instructions on how
      to delete and disable cookies, and other tracking/recording tools. Depending on your type of
      device, it may not be possible to delete or disable tracking mechanisms on your mobile device.
      Note that disabling cookies and/or other tracking tools prevents browngrotta arts or its
      business partners from tracking your browser’s activities in relation to the Service. However,
      doing so may disable many of the features available through the Service.
    </p>
    <p class="privacy-policy__text privacy-policy__new-paragraph text-base">
      <span class="privacy-policy__paragraph">
        How long we keep your private profile information:
      </span>
      Following termination of your User account, browngrotta arts may retain your private profile
      information for a commercially reasonable time for backup, archival, or audit purposes. To be
      clear, any information that you choose to make public on the service may not be removable.
    </p>

    <p class="privacy-policy__sub-title privacy-policy__new-paragraph text-link">
      Children’s Privacy
    </p>
    <p class="privacy-policy__text text-base">
      browngrotta arts will not knowingly collect personally identifiable information from any
      person that is actually known to us to be a child under the age of 13 years old. If you are
      under 13 years of age, please do not use or access our website or services.
    </p>

    <p class="privacy-policy__sub-title privacy-policy__new-paragraph text-link">
      Links to Other Websites and Services
    </p>
    <p class="privacy-policy__text text-base">
      We are not responsible for the practices employed by websites or services linked to or from
      the Service, including the information or content contained therein. Please remember that when
      you use a link to go from the Service to another website, our Privacy Policy does not apply to
      third-party websites or services. Your browsing and interaction on any third-party website or
      service, including those that have a link on our website, are subject to that third party’s
      own rules and policies. In addition, you agree that we are not responsible and we do not have
      control over any third parties that you authorize to access your User Content. You will
      receive a notice when you leave our website and go to a third-party site. If you are using a
      third-party website or service (like Facebook, Google groups, or an IRC chatroom) and you
      allow such a third-party access to your User Content you do so at your own risk.
    </p>

    <p class="privacy-policy__sub-title privacy-policy__new-paragraph text-link">
      Changes to this Privacy Policy
    </p>
    <p class="privacy-policy__text text-base">
      We may modify this Privacy Policy from time to time and with no notice to Users. It is your
      responsibility to check this Privacy Policy for any changes. Changes will be effective
      immediately upon their posting to this site.
    </p>

    <p class="privacy-policy__sub-title privacy-policy__new-paragraph text-link">
      For EU Residents:
    </p>
    <p class="privacy-policy__text text-base">
      In an effort to give you more control over how your data is used, we would like to make sure
      you are fully aware of all of your data protection rights. Every user is entitled to the
      following:
    </p>

    <ul class="privacy-policy__list">
      <li>
        <span class="privacy-policy__text privacy-policy__list-item text-base"
          >The right to access
        </span>
        <span class="privacy-policy__text text-base">
          – You have the right to request copies of your personal data from us.</span
        >
      </li>
      <li>
        <span class="privacy-policy__text privacy-policy__list-item text-base"
          >The right to correction
        </span>
        <span class="privacy-policy__text text-base">
          – You have the right to request that we correct any information you believe is
          inaccurate.</span
        >
      </li>
      <li>
        <span class="privacy-policy__text privacy-policy__list-item text-base"
          >The right to erasure
        </span>
        <span class="privacy-policy__text text-base">
          – You have the right to request we erase your personal information under certain
          conditions.</span
        >
      </li>
      <li>
        <span class="privacy-policy__text privacy-policy__list-item text-base"
          >The right to restrict processing
        </span>
        <span class="privacy-policy__text text-base">
          – You have the right to request that we restrict the processing of your personal
          information under certain conditions.</span
        >
      </li>
      <li>
        <span class="privacy-policy__text privacy-policy__list-item text-base"
          >The right to object to processing
        </span>
        <span class="privacy-policy__text text-base">
          – You have the right to object to our processing of your personal data under certain
          conditions.</span
        >
      </li>
      <li>
        <span class="privacy-policy__text privacy-policy__list-item text-base"
          >The right to data portability
        </span>
        <span class="privacy-policy__text text-base">
          – You have the right to request that we transfer the data that we have collected to
          another organization or directly to you under certain conditions.</span
        >
      </li>
    </ul>

    <p class="privacy-policy__text privacy-policy__new-paragraph text-base">
      If you make a request, we have one month from our receipt of your notice to respond to you. If
      you would like to exercise any of these rights, please contact us at email:
      art@browngrotta.com<br />Call us at: 203.834.0623<br />Or write to us: 276 Ridgefield Road •
      Wilton, CT • 06897
    </p>

    <p class="privacy-policy__sub-title privacy-policy__new-paragraph text-link">
      For California Residents:
    </p>
    <p class="privacy-policy__text text-base">
      In an effort to give you more control over how your data is used, we would like to make sure
      you are fully aware of all of your data protection rights. Every user is entitled to the
      following:
    </p>

    <ul class="privacy-policy__list">
      <li>
        <span class="privacy-policy__text privacy-policy__list-item text-base"
          >The right to know
        </span>
      </li>
      <li>
        <span class="privacy-policy__text privacy-policy__list-item text-base"
          >The right to deletion
        </span>
      </li>
      <li>
        <span class="privacy-policy__text privacy-policy__list-item text-base"
          >The right to Non-discrimination
        </span>
      </li>
      <li>
        <span class="privacy-policy__text privacy-policy__list-item text-base"
          >The right to opt out of sales
        </span>
      </li>
    </ul>

    <p class="privacy-policy__text privacy-policy__new-paragraph text-base">
      If you make a request, we have one month from our receipt of your notice to respond to you. If
      you would like to exercise any of these rights, please contact us at email:
      art@browngrotta.com<br />Call us at: 203.834.0623<br />Or write to us: 276 Ridgefield Road •
      Wilton, CT • 06897
    </p>

    <p class="privacy-policy__text privacy-policy__new-paragraph text-base">
      <span class="privacy-policy__paragraph">Last Posted: </span>
      May 2022
    </p>

    <IconButton
      class="privacy-policy__close button--md button--outline-white"
      @click="$router.go(-1)"
    >
      <IconClose width="18" height="18"></IconClose>
    </IconButton>
  </section>
</template>

<script>
import IconButton from "@/components/buttons/IconButton.vue";
import IconClose from "@/components/icons/IconClose.vue";

export default {
  components: {
    IconButton,
    IconClose,
  },
  head: {
    title: function () {
      return {
        inner: "Privacy Policy browngrotta arts",
      };
    },
    meta: function () {
      return [
        {
          name: "description",
          itemprop: "description",
          content: "Privacy Policy browngrotta arts",
          id: "meta:description",
        },
        {
          itemprop: "name",
          content: "Privacy Policy browngrotta arts",
          id: "meta:name",
        },
        {
          itemprop: "image",
          content: `${process.env.VUE_APP_FRONTEND_URL}/img/icons/favicon-500x500.png`,
          id: "meta:image",
        },
        {
          property: "og:url",
          content: `${process.env.VUE_APP_FRONTEND_URL}${this.$route.path}`,
          id: "og:url",
        },
        {
          property: "og:type",
          content: "website",
          id: "og:type",
        },
        {
          property: "og:title",
          content: "Privacy Policy browngrotta arts",
          id: "og:title",
        },
        {
          property: "og:image",
          content: `${process.env.VUE_APP_FRONTEND_URL}/img/icons/favicon-500x500.png`,
          id: "og:image",
        },
        {
          property: "og:image:alt",
          content: "Privacy Policy browngrotta arts",
          id: "og:image:alt",
        },
        {
          property: "og:description",
          content: "Description Privacy Policy browngrotta arts",
          id: "og:description",
        },
        {
          property: "og:site_name",
          content: process.env.VUE_APP_FRONTEND_URL,
          id: "og:site_name",
        },
        {
          property: "og:locale",
          content: "en_US",
          id: "og:locale",
        },
        {
          name: "twitter:card",
          content: "summary",
          id: "twitter:card",
        },
        {
          name: "twitter:site",
          content: "@BrowngrottaArts",
          id: "twitter:site",
        },
        {
          name: "twitter:creator",
          content: "@BrowngrottaArts",
          id: "twitter:creator",
        },
        {
          name: "twitter:url",
          content: `${process.env.VUE_APP_FRONTEND_URL}${this.$route.path}`,
          id: "twitter:url",
        },
        {
          name: "twitter:title",
          content: "Privacy Policy browngrotta arts",
          id: "twitter:title",
        },
        {
          name: "twitter:description",
          content: "Description Privacy Policy browngrotta arts",
          id: "twitter:description",
        },
        {
          name: "twitter:image",
          content: `${process.env.VUE_APP_FRONTEND_URL}/img/icons/favicon-500x500.png`,
          id: "twitter:image",
        },
        {
          name: "twitter:image:alt",
          content: "Privacy Policy browngrotta arts",
          id: "twitter:image:alt",
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.privacy-policy {
  position: relative;
  padding: 17rem 31.5rem 18rem;
  @media screen and (max-width: $md) {
    padding: 17rem 10.5rem 18rem;
  }
  @media screen and (max-width: $xs) {
    padding: 17rem 25px 18rem;
  }
  &__title {
    font-size: 4rem;
    line-height: 1.25;
    letter-spacing: -1.33px;
    margin-bottom: 3.5rem;
  }
  &__sub-title {
    margin-bottom: 0.5rem;
  }
  &__text {
    font-weight: 100;
    color: rgba($color: $white, $alpha: 0.8);
    margin-bottom: 1.5rem;
    & > span {
      font-weight: 400;
    }
  }
  &__new-paragraph {
    margin-top: 3rem;
  }

  &__list {
    margin-left: 105px;
    @media screen and (max-width: $xs) {
      margin-left: 0px;
    }

    & > li {
      &::before {
        width: 0.55rem;
        height: 0.55rem;
        margin-right: 5px;
        background-color: white;
        position: relative;
        float: left;
        top: 1rem;
      }
    }
  }

  &__list-item {
    text-decoration: underline;
  }

  &__close {
    position: absolute;
    top: 4.5rem;
    right: 10.5rem;
    z-index: 1000;
    @media screen and (max-width: $md) {
      right: 5rem;
    }
    @media screen and (max-width: $xs) {
      right: 25px;
    }
  }
}
</style>
